import React, { Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import ActionComponent from "./pages/SimplePage";
import "./App.css";
import configureStore from "./redux/store";
import { ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import Header from "./components/CommonHeader/Header";
import Footer from "./components/CommonFooter/Footer";
import Login from "./pages/Onboarding/Login";
import Signup from "./pages/Onboarding/Signup";
import HomepageComponent from "./pages/Homepage/Homepage";
import LandingPage from "./pages/LandingPage";
import RoutesComp from "./routes";
import Facility from "./sections/Homepage/FacilitySection";
import FacilityDetails from "sections/Homepage/FacilityDetails";
import ParticipantAgreement from "sections/Homepage/ParticipantAgreementSection";
import TabsSection from "sections/Homepage/TabsSection";
import DashboardSection from "sections/Homepage/DashboardSection";
import Loader from "pages/Loader";
import 'azure-maps-control/dist/atlas.min.css';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
} from "@azure/msal-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import EvLoader from "utils/loader/EvLoader";

const store = configureStore();

console.log("build updated on 5th June 7:30PM");


const App = (props) => {


  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Suspense fallback={<Loader fallBackLoader={true} />}>
            <MsalProvider instance={props.instance}>
              <UnauthenticatedTemplate>
                <Header />
                <LandingPage />
                <Footer />
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <>
                  <RoutesComp />
                </>
              </AuthenticatedTemplate>
              <HomepageComponent />
            </MsalProvider>
          </Suspense>

          {/* <ActionComponent /> */}
          {/* <Login /> */}
          {/* {/* <Signup /> */}
          {/* <HomepageComponent /> */}
          {/* <LandingPage /> */}
          {/* <Facility /> */}
          {/* <FacilityDetails /> */}
          {/* <ParticipantAgreement /> */}
          {/* <Footer /> */}
        </LocalizationProvider>
      </ThemeProvider>
      <EvLoader />
      
    </Provider>
  );
};

export default App;
