//---------------------------------ADMIN FACILITY TYPES--------------------------------

export const FETCH_ADMIN_FACILITY_LIST_REQUEST =
  "FETCH_ADMIN_FACILITY_LIST_REQUEST";
export const FETCH_ADMIN_FACILITY_LIST_SUCCESS =
  "FETCH_ADMIN_FACILITY_LIST_SUCCESS";
export const FETCH_ADMIN_FACILITY_LIST_FAILURE =
  "FETCH_ADMIN_FACILITY_LIST_FAILURE";

export const FETCH_ADMIN_FACILITY_LIST_ACTIVE_REQUEST =
  "FETCH_ADMIN_FACILITY_LIST_ACTIVE_REQUEST";
export const FETCH_ADMIN_FACILITY_LIST_ACTIVE_SUCCESS =
  "FETCH_ADMIN_FACILITY_LIST_ACTIVE_SUCCESS";
export const FETCH_ADMIN_FACILITY_LIST_ACTIVE_FAILURE =
  "FETCH_ADMIN_FACILITY_LIST_ACTIVE_FAILURE";

export const FETCH_ADMIN_FACILITY_LIST_INPROCESS_REQUEST =
  "FETCH_ADMIN_FACILITY_LIST_INPROCESS_REQUEST";
export const FETCH_ADMIN_FACILITY_LIST_INPROCESS_SUCCESS =
  "FETCH_ADMIN_FACILITY_LIST_INPROCESS_SUCCESS";
export const FETCH_ADMIN_FACILITY_LIST_INPROCESS_FAILURE =
  "FETCH_ADMIN_FACILITY_LIST_INPROCESS_FAILURE";

export const FETCH_ADMIN_FACILITY_DETAILS_REQUEST =
  "FETCH_ADMIN_FACILITY_DETAILS_REQUEST";
export const FETCH_ADMIN_FACILITY_DETAILS_SUCCESS =
  "FETCH_ADMIN_FACILITY_DETAILS_SUCCESS";
export const FETCH_ADMIN_FACILITY_DETAILS_FAILURE =
  "FETCH_ADMIN_FACILITY_DETAILS_FAILURE";

export const DELETE_ADMIN_FACILITY_REQUEST = "DELETE_ADMIN_FACILITY_REQUEST";
export const DELETE_ADMIN_FACILITY_SUCCESS = "DELETE_ADMIN_FACILITY_SUCCESS";
export const DELETE_ADMIN_FACILITY_FAILURE = "DELETE_ADMIN_FACILITY_FAILURE";

export const FETCH_ADMIN_FACILITY_BY_ID_REQUEST =
  "FETCH_ADMIN_FACILITY_BY_ID_REQUEST";
export const FETCH_ADMIN_FACILITY_BY_ID_SUCCESS =
  "FETCH_ADMIN_FACILITY_BY_ID_SUCCESS";
export const FETCH_ADMIN_FACILITY_BY_ID_FAILURE =
  "FETCH_ADMIN_FACILITY_BY_ID_FAILURE";

export const ADD_ADMIN_FACILITY_CHARACTERISTICS_REQUEST =
  "ADD_ADMIN_FACILITY_CHARACTERISTICS_REQUEST";
export const ADD_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS =
  "ADD_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS";
export const ADD_ADMIN_FACILITY_CHARACTERISTICS_FAILURE =
  "ADD_ADMIN_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_ADMIN_FACILITY_CHARACTERISTICS_REQUEST =
  "FETCH_ADMIN_FACILITY_CHARACTERISTICS_REQUEST";
export const FETCH_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS =
  "FETCH_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS";
export const FETCH_ADMIN_FACILITY_CHARACTERISTICS_FAILURE =
  "FETCH_ADMIN_FACILITY_CHARACTERISTICS_FAILURE";

export const UPDATE_ADMIN_FACILITY_CHARACTERISTICS_REQUEST =
  "UPDATE_ADMIN_FACILITY_CHARACTERISTICS_REQUEST";
export const UPDATE_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS =
  "UPDATE_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS";
export const UPDATE_ADMIN_FACILITY_CHARACTERISTICS_FAILURE =
  "UPDATE_ADMIN_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_ADMIN_FACILITY_STATUS_REQUEST =
  "FETCH_ADMIN_FACILITY_STATUS_REQUEST";
export const FETCH_ADMIN_FACILITY_STATUS_SUCCESS =
  "FETCH_ADMIN_FACILITY_STATUS_SUCCESS";
export const FETCH_ADMIN_FACILITY_STATUS_FAILURE =
  "FETCH_ADMIN_FACILITY_STATUS_FAILURE";

export const UPDATE_ADMIN_FACILITY_STATUS_REQUEST =
  "UPDATE_ADMIN_FACILITY_STATUS_REQUEST";
export const UPDATE_ADMIN_FACILITY_STATUS_SUCCESS =
  "UPDATE_ADMIN_FACILITY_STATUS_SUCCESS";
export const UPDATE_ADMIN_FACILITY_STATUS_FAILURE =
  "UPDATE_ADMIN_FACILITY_STATUS_FAILURE";

export const ADMIN_ASSIGN_FACILITIES_REQUEST =
  "ADMIN_ASSIGN_FACILITIES_REQUEST";
export const ADMIN_ASSIGN_FACILITIES_SUCCESS =
  "ADMIN_ASSIGN_FACILITIES_SUCCESS";
export const ADMIN_ASSIGN_FACILITIES_FAILURE =
  "ADMIN_ASSIGN_FACILITIES_FAILURE";

export const FETCH_ADMIN_FACILITIES_DROPDOWN_REQUEST =
  "FETCH_ADMIN_FACILITIES_DROPDOWN_REQUEST";
export const FETCH_ADMIN_FACILITIES_DROPDOWN_SUCCESS =
  "FETCH_ADMIN_FACILITIES_DROPDOWN_SUCCESS";
export const FETCH_ADMIN_FACILITIES_DROPDOWN_FAILURE =
  "FETCH_ADMIN_FACILITIES_DROPDOWN_FAILURE";

export const FETCH_ADMIN_STATISTICS_REQUEST = "FETCH_ADMIN_STATISTICS_REQUEST";
export const FETCH_ADMIN_STATISTICS_SUCCESS = "FETCH_ADMIN_STATISTICS_SUCCESS";
export const FETCH_ADMIN_STATISTICS_FAILURE = "FETCH_ADMIN_STATISTICS_FAILURE";

export const DOWNLOAD_FACILITIES_BULK_REQUEST =
  "DOWNLOAD_FACILITIES_BULK_REQUEST";
export const DOWNLOAD_FACILITIES_BULK_SUCCESS =
  "DOWNLOAD_FACILITIES_BULK_SUCCESS";
export const DOWNLOAD_FACILITIES_BULK_FAILURE =
  "DOWNLOAD_FACILITIES_BULK_FAILURE";

export const DOWNLOAD_FACILITY_ROW_REQUEST = "DOWNLOAD_FACILITY_ROW_REQUEST";
export const DOWNLOAD_FACILITY_ROW_SUCCESS = "DOWNLOAD_FACILITY_ROW_SUCCESS";
export const DOWNLOAD_FACILITY_ROW_FAILURE = "DOWNLOAD_FACILITY_ROW_FAILURE";

export const FETCH_ADMIN_FACILITY_MEASURE_REPORT_LIST_REQUEST =
  "FETCH_ADMIN_FACILITY_MEASURE_REPORT_LIST_REQUEST";
export const FETCH_ADMIN_FACILITY_MEASURE_REPORT_LIST_SUCCESS =
  "FETCH_ADMIN_FACILITY_MEASURE_REPORT_LIST_SUCCESS";
export const FETCH_ADMIN_FACILITY_MEASURE_REPORT_LIST_FAILURE =
  "FETCH_ADMIN_FACILITY_MEASURE_REPORT_LIST_FAILURE";

export const ADMIN_ADD_FACILITY_MEASURE_REPORT_REQUEST =
  "ADMIN_ADD_FACILITY_MEASURE_REPORT_REQUEST";
export const ADMIN_ADD_FACILITY_MEASURE_REPORT_SUCCESS =
  "ADMIN_ADD_FACILITY_MEASURE_REPORT_SUCCESS";
export const ADMIN_ADD_FACILITY_MEASURE_REPORT_FAILURE =
  "ADMIN_ADD_FACILITY_MEASURE_REPORT_FAILURE";

export const FETCH_ADMIN_FACILITY_MEASURE_REPORT_DETAILS_REQUEST =
  "FETCH_ADMIN_FACILITY_MEASURE_REPORT_DETAILS_REQUEST";
export const FETCH_ADMIN_FACILITY_MEASURE_REPORT_DETAILS_SUCCESS =
  "FETCH_ADMIN_FACILITY_MEASURE_REPORT_DETAILS_SUCCESS";
export const FETCH_ADMIN_FACILITY_MEASURE_REPORT_DETAILS_FAILURE =
  "FETCH_ADMIN_FACILITY_MEASURE_REPORT_DETAILS_FAILURE";

export const UPDATE_ADMIN_FACILITY_MEASURE_REPORT_REQUEST =
  "UPDATE_ADMIN_FACILITY_MEASURE_REPORT_REQUEST";
export const UPDATE_ADMIN_FACILITY_MEASURE_REPORT_SUCCESS =
  "UPDATE_ADMIN_FACILITY_MEASURE_REPORT_SUCCESS";
export const UPDATE_ADMIN_FACILITY_MEASURE_REPORT_FAILURE =
  "UPDATE_ADMIN_FACILITY_MEASURE_REPORT_FAILURE";

export const DELETE_ADMIN_FACILITY_MEASURE_REPORT_REQUEST =
  "DELETE_ADMIN_FACILITY_MEASURE_REPORT_REQUEST";
export const DELETE_ADMIN_FACILITY_MEASURE_REPORT_SUCCESS =
  "DELETE_ADMIN_FACILITY_MEASURE_REPORT_SUCCESS";
export const DELETE_ADMIN_FACILITY_MEASURE_REPORT_FAILURE =
  "DELETE_ADMIN_FACILITY_MEASURE_REPORT_FAILURE";

export const FETCH_ADMIN_FACILITY_DOCUMENT_LIST_REQUEST =
  "FETCH_ADMIN_FACILITY_DOCUMENT_LIST_REQUEST";
export const FETCH_ADMIN_FACILITY_DOCUMENT_LIST_SUCCESS =
  "FETCH_ADMIN_FACILITY_DOCUMENT_LIST_SUCCESS";
export const FETCH_ADMIN_FACILITY_DOCUMENT_LIST_FAILURE =
  "FETCH_ADMIN_FACILITY_DOCUMENT_LIST_FAILURE";

export const ADMIN_ADD_FACILITY_DOCUMENT_REQUEST =
  "ADMIN_ADD_FACILITY_DOCUMENT_REQUEST";
export const ADMIN_ADD_FACILITY_DOCUMENT_SUCCESS =
  "ADMIN_ADD_FACILITY_DOCUMENT_SUCCESS";
export const ADMIN_ADD_FACILITY_DOCUMENT_FAILURE =
  "ADMIN_ADD_FACILITY_DOCUMENT_FAILURE";

export const FETCH_ADMIN_FACILITY_DOCUMENT_DETAILS_REQUEST =
  "FETCH_ADMIN_FACILITY_DOCUMENT_DETAILS_REQUEST";
export const FETCH_ADMIN_FACILITY_DOCUMENT_DETAILS_SUCCESS =
  "FETCH_ADMIN_FACILITY_DOCUMENT_DETAILS_SUCCESS";
export const FETCH_ADMIN_FACILITY_DOCUMENT_DETAILS_FAILURE =
  "FETCH_ADMIN_FACILITY_DOCUMENT_DETAILS_FAILURE";

export const UPDATE_ADMIN_FACILITY_DOCUMENT_REQUEST =
  "UPDATE_ADMIN_FACILITY_DOCUMENT_REQUEST";
export const UPDATE_ADMIN_FACILITY_DOCUMENT_SUCCESS =
  "UPDATE_ADMIN_FACILITY_DOCUMENT_SUCCESS";
export const UPDATE_ADMIN_FACILITY_DOCUMENT_FAILURE =
  "UPDATE_ADMIN_FACILITY_DOCUMENT_FAILURE";

export const DELETE_ADMIN_FACILITY_DOCUMENT_REQUEST =
  "DELETE_ADMIN_FACILITY_DOCUMENT_REQUEST";
export const DELETE_ADMIN_FACILITY_DOCUMENT_SUCCESS =
  "DELETE_ADMIN_FACILITY_DOCUMENT_SUCCESS";
export const DELETE_ADMIN_FACILITY_DOCUMENT_FAILURE =
  "DELETE_ADMIN_FACILITY_DOCUMENT_FAILURE";

//---------------------------------ADMIN METER TYPES--------------------------------

export const FETCH_ADMIN_METER_LIST_REQUEST = "FETCH_ADMIN_METER_LIST_REQUEST";
export const FETCH_ADMIN_METER_LIST_SUCCESS = "FETCH_ADMIN_METER_LIST_SUCCESS";
export const FETCH_ADMIN_METER_LIST_FAILURE = "FETCH_ADMIN_METER_LIST_FAILURE";

export const ADD_ADMIN_METER_REQUEST = "ADD_ADMIN_METER_REQUEST";
export const ADD_ADMIN_METER_SUCCESS = "ADD_ADMIN_METER_SUCCESS";
export const ADD_ADMIN_METER_FAILURE = "ADD_ADMIN_METER_FAILURE";

export const UPDATE_ADMIN_METER_REQUEST = "UPDATE_ADMIN_METER_REQUEST";
export const UPDATE_ADMIN_METER_SUCCESS = "UPDATE_ADMIN_METER_SUCCESS";
export const UPDATE_ADMIN_METER_FAILURE = "UPDATE_ADMIN_METER_FAILURE";

export const FETCH_ADMIN_METER_DETAILS_REQUEST =
  "FETCH_ADMIN_METER_DETAILS_REQUEST";
export const FETCH_ADMIN_METER_DETAILS_SUCCESS =
  "FETCH_ADMIN_METER_DETAILS_SUCCESS";
export const FETCH_ADMIN_METER_DETAILS_FAILURE =
  "FETCH_ADMIN_METER_DETAILS_FAILURE";

export const DELETE_ADMIN_METER_REQUEST = "DELETE_ADMIN_METER_REQUEST";
export const DELETE_ADMIN_METER_SUCCESS = "DELETE_ADMIN_METER_SUCCESS";
export const DELETE_ADMIN_METER_FAILURE = "DELETE_ADMIN_METER_FAILURE";

export const FETCH_ADMIN_METER_STATISTICS_REQUEST =
  "FETCH_ADMIN_METER_STATISTICS_REQUEST";
export const FETCH_ADMIN_METER_STATISTICS_SUCCESS =
  "FETCH_ADMIN_METER_STATISTICS_SUCCESS";
export const FETCH_ADMIN_METER_STATISTICS_FAILURE =
  "FETCH_ADMIN_METER_STATISTICS_FAILURE";

//------------------------------------ADMIN ENTRIES TYPES--------------------------------

export const FETCH_ADMIN_ENTRIES_LIST_REQUEST =
  "FETCH_ADMIN_ENTRIES_LIST_REQUEST";
export const FETCH_ADMIN_ENTRIES_LIST_SUCCESS =
  "FETCH_ADMIN_ENTRIES_LIST_SUCCESS";
export const FETCH_ADMIN_ENTRIES_LIST_FAILURE =
  "FETCH_ADMIN_ENTRIES_LIST_FAILURE";

//------------------------------------ADMIN COMPANY TYPES--------------------------------

export const FETCH_ADMIN_COMPANY_LIST_REQUEST =
  "FETCH_ADMIN_COMPANY_LIST_REQUEST";
export const FETCH_ADMIN_COMPANY_LIST_SUCCESS =
  "FETCH_ADMIN_COMPANY_LIST_SUCCESS";
export const FETCH_ADMIN_COMPANY_LIST_FAILURE =
  "FETCH_ADMIN_COMPANY_LIST_FAILURE";

export const FETCH_ADMIN_COMPANY_DETAILS_REQUEST =
  "FETCH_ADMIN_COMPANY_DETAILS_REQUEST";
export const FETCH_ADMIN_COMPANY_DETAILS_SUCCESS =
  "FETCH_ADMIN_COMPANY_DETAILS_SUCCESS";
export const FETCH_ADMIN_COMPANY_DETAILS_FAILURE =
  "FETCH_ADMIN_COMPANY_DETAILS_FAILURE";

export const ADMIN_COMPANY_SEND_ALERT_REQUEST =
  "ADMIN_COMPANY_SEND_ALERT_REQUEST";
export const ADMIN_COMPANY_SEND_ALERT_SUCCESS =
  "ADMIN_COMPANY_SEND_ALERT_SUCCESS";
export const ADMIN_COMPANY_SEND_ALERT_FAILURE =
  "ADMIN_COMPANY_SEND_ALERT_FAILURE";

export const ADMIN_COMPANY_UPDATE_STATUS_REQUEST =
  "ADMIN_COMPANY_UPDATE_STATUS_REQUEST";
export const ADMIN_COMPANY_UPDATE_STATUS_SUCCESS =
  "ADMIN_COMPANY_UPDATE_STATUS_SUCCESS";
export const ADMIN_COMPANY_UPDATE_STATUS_FAILURE =
  "ADMIN_COMPANY_UPDATE_STATUS_FAILURE";

export const FETCH_ADMIN_COMPANIES_DROPDOWN_REQUEST =
  "FETCH_ADMIN_COMPANIES_DROPDOWN_REQUEST";
export const FETCH_ADMIN_COMPANIES_DROPDOWN_SUCCESS =
  "FETCH_ADMIN_COMPANIES_DROPDOWN_SUCCESS";
export const FETCH_ADMIN_COMPANIES_DROPDOWN_FAILURE =
  "FETCH_ADMIN_COMPANIES_DROPDOWN_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const CHANGE_COMPANY_SUPER_ADMIN_REQUEST =
  "CHANGE_COMPANY_SUPER_ADMIN_REQUEST";
export const CHANGE_COMPANY_SUPER_ADMIN_SUCCESS =
  "CHANGE_COMPANY_SUPER_ADMIN_SUCCESS";
export const CHANGE_COMPANY_SUPER_ADMIN_FAILURE =
  "CHANGE_COMPANY_SUPER_ADMIN_FAILURE";

export const FETCH_COMPANY_USER_LIST_REQUEST =
  "FETCH_COMPANY_USER_LIST_REQUEST";
export const FETCH_COMPANY_USER_LIST_SUCCESS =
  "FETCH_COMPANY_USER_LIST_SUCCESS";
export const FETCH_COMPANY_USER_LIST_FAILURE =
  "FETCH_COMPANY_USER_LIST_FAILURE";

export const FETCH_USERS_BY_COMPANY_REQUEST = "FETCH_USERS_BY_COMPANY_REQUEST";
export const FETCH_USERS_BY_COMPANY_SUCCESS = "FETCH_USERS_BY_COMPANY_SUCCESS";
export const FETCH_USERS_BY_COMPANY_FAILURE = "FETCH_USERS_BY_COMPANY_FAILURE";

//---------------------------------BASELINE TYPES--------------------------------
export const ADMIN_SUFFICIENCY_CHECK_REQUEST =
  "ADMIN_SUFFICIENCY_CHECK_REQUEST";
export const ADMIN_SUFFICIENCY_CHECK_SUCCESS =
  "ADMIN_SUFFICIENCY_CHECK_SUCCESS";
export const ADMIN_SUFFICIENCY_CHECK_FAILURE =
  "ADMIN_SUFFICIENCY_CHECK_FAILURE";

export const ADMIN_INDEPENDENT_VARIABLE_LIST_REQUEST =
  "ADMIN_INDEPENDENT_VARIABLE_LIST_REQUEST";
export const ADMIN_INDEPENDENT_VARIABLE_LIST_SUCCESS =
  "ADMIN_INDEPENDENT_VARIABLE_LIST_SUCCESS";
export const ADMIN_INDEPENDENT_VARIABLE_LIST_FAILURE =
  "ADMIN_INDEPENDENT_VARIABLE_LIST_FAILURE";

export const FETCH_ADMIN_BASELINE_PERIOD_REQUEST =
  "FETCH_ADMIN_BASELINE_PERIOD_REQUEST";
export const FETCH_ADMIN_BASELINE_PERIOD_SUCCESS =
  "FETCH_ADMIN_BASELINE_PERIOD_SUCCESS";
export const FETCH_ADMIN_BASELINE_PERIOD_FAILURE =
  "FETCH_ADMIN_BASELINE_PERIOD_FAILURE";

export const FETCH_ADMIN_STATIONS_DETAILS_REQUEST =
  "FETCH_ADMIN_STATIONS_DETAILS_REQUEST";
export const FETCH_ADMIN_STATIONS_DETAILS_SUCCESS =
  "FETCH_ADMIN_STATIONS_DETAILS_SUCCESS";
export const FETCH_ADMIN_STATIONS_DETAILS_FAILURE =
  "FETCH_ADMIN_STATIONS_DETAILS_FAILURE";

export const FETCH_ADMIN_ISSUE_DETAILS_REQUEST =
  "FETCH_ADMIN_ISSUE_DETAILS_REQUEST";
export const FETCH_ADMIN_ISSUE_DETAILS_SUCCESS =
  "FETCH_ADMIN_ISSUE_DETAILS_SUCCESS";
export const FETCH_ADMIN_ISSUE_DETAILS_FAILURE =
  "FETCH_ADMIN_ISSUE_DETAILS_FAILURE";

export const ADMIN_ADD_BASELINE_DB_REQUEST = "ADMIN_ADD_BASELINE_DB_REQUEST";
export const ADMIN_ADD_BASELINE_DB_SUCCESS = "ADMIN_ADD_BASELINE_DB_SUCCESS";
export const ADMIN_ADD_BASELINE_DB_FAILURE = "ADMIN_ADD_BASELINE_DB_FAILURE";

export const FETCH_ADMIN_BASELINE_DETAILS_DB_REQUEST =
  "FETCH_ADMIN_BASELINE_DETAILS_DB_REQUEST";
export const FETCH_ADMIN_BASELINE_DETAILS_DB_SUCCESS =
  "FETCH_ADMIN_BASELINE_DETAILS_DB_SUCCESS";
export const FETCH_ADMIN_BASELINE_DETAILS_DB_FAILURE =
  "FETCH_ADMIN_BASELINE_DETAILS_DB_FAILURE";

export const UPDATE_ADMIN_BASELINE_DETAILS_DB_REQUEST =
  "UPDATE_ADMIN_BASELINE_DETAILS_DB_REQUEST";
export const UPDATE_ADMIN_BASELINE_DETAILS_DB_SUCCESS =
  "UPDATE_ADMIN_BASELINE_DETAILS_DB_SUCCESS";
export const UPDATE_ADMIN_BASELINE_DETAILS_DB_FAILURE =
  "UPDATE_ADMIN_BASELINE_DETAILS_DB_FAILURE";

export const FETCH_ADMIN_BASELINE_LIST_DB_REQUEST =
  "FETCH_ADMIN_BASELINE_LIST_DB_REQUEST";
export const FETCH_ADMIN_BASELINE_LIST_DB_SUCCESS =
  "FETCH_ADMIN_BASELINE_LIST_DB_SUCCESS";
export const FETCH_ADMIN_BASELINE_LIST_DB_FAILURE =
  "FETCH_ADMIN_BASELINE_LIST_DB_FAILURE";

export const ADMIN_ADD_ASSIGNEE_TO_BASELINE_DB_REQUEST =
  "ADMIN_ADD_ASSIGNEE_TO_BASELINE_DB_REQUEST";
export const ADMIN_ADD_ASSIGNEE_TO_BASELINE_DB_SUCCESS =
  "ADMIN_ADD_ASSIGNEE_TO_BASELINE_DB_SUCCESS";
export const ADMIN_ADD_ASSIGNEE_TO_BASELINE_DB_FAILURE =
  "ADMIN_ADD_ASSIGNEE_TO_BASELINE_DB_FAILURE";

export const SUBMIT_ADMIN_REJECTED_BASELINE_DB_REQUEST =
  "SUBMIT_ADMIN_REJECTED_BASELINE_DB_REQUEST";
export const SUBMIT_ADMIN_REJECTED_BASELINE_DB_SUCCESS =
  "SUBMIT_ADMIN_REJECTED_BASELINE_DB_SUCCESS";
export const SUBMIT_ADMIN_REJECTED_BASELINE_DB_FAILURE =
  "SUBMIT_ADMIN_REJECTED_BASELINE_DB_FAILURE";

export const SHOW_ADMIN_OBSERVE_DATA_REQUEST =
  "SHOW_ADMIN_OBSERVE_DATA_REQUEST";
export const SHOW_ADMIN_OBSERVE_DATA_SUCCESS =
  "SHOW_ADMIN_OBSERVE_DATA_SUCCESS";
export const SHOW_ADMIN_OBSERVE_DATA_FAILURE =
  "SHOW_ADMIN_OBSERVE_DATA_FAILURE";

export const SUBMIT_ADMIN_BASELINE_D_T_REQUEST =
  "SUBMIT_ADMIN_BASELINE_D_T_REQUEST";
export const SUBMIT_ADMIN_BASELINE_D_T_SUCCESS =
  "SUBMIT_ADMIN_BASELINE_D_T_SUCCESS";
export const SUBMIT_ADMIN_BASELINE_D_T_FAILURE =
  "SUBMIT_ADMIN_BASELINE_D_T_FAILURE";

export const FETCH_ADMIN_DATA_EXPLORATION_SUMMARY_REQUEST =
  "FETCH_ADMIN_DATA_EXPLORATION_SUMMARY_REQUEST";
export const FETCH_ADMIN_DATA_EXPLORATION_SUMMARY_SUCCESS =
  "FETCH_ADMIN_DATA_EXPLORATION_SUMMARY_SUCCESS";
export const FETCH_ADMIN_DATA_EXPLORATION_SUMMARY_FAILURE =
  "FETCH_ADMIN_DATA_EXPLORATION_SUMMARY_FAILURE";

export const FETCH_ADMIN_RAW_SUMMARY_METER_LIST_REQUEST =
  "FETCH_ADMIN_RAW_SUMMARY_METER_LIST_REQUEST";
export const FETCH_ADMIN_RAW_SUMMARY_METER_LIST_SUCCESS =
  "FETCH_ADMIN_RAW_SUMMARY_METER_LIST_SUCCESS";
export const FETCH_ADMIN_RAW_SUMMARY_METER_LIST_FAILURE =
  "FETCH_ADMIN_RAW_SUMMARY_METER_LIST_FAILURE";

export const FETCH_ADMIN_OUTLIERS_SETTINGS_REQUEST =
  "FETCH_ADMIN_OUTLIERS_SETTINGS_REQUEST";
export const FETCH_ADMIN_OUTLIERS_SETTINGS_SUCCESS =
  "FETCH_ADMIN_OUTLIERS_SETTINGS_SUCCESS";
export const FETCH_ADMIN_OUTLIERS_SETTINGS_FAILURE =
  "FETCH_ADMIN_OUTLIERS_SETTINGS_FAILURE";

export const FETCH_FACILITY_THRESHOLD_REQUEST =
  "FETCH_FACILITY_THRESHOLD_REQUEST";
export const FETCH_FACILITY_THRESHOLD_SUCCESS =
  "FETCH_FACILITY_THRESHOLD_SUCCESS";
export const FETCH_FACILITY_THRESHOLD_FAILURE =
  "FETCH_FACILITY_THRESHOLD_FAILURE";

export const UPDATE_FACILITY_THRESHOLD_REQUEST =
  "UPDATE_FACILITY_THRESHOLD_REQUEST";
export const UPDATE_FACILITY_THRESHOLD_SUCCESS =
  "UPDATE_FACILITY_THRESHOLD_SUCCESS";
export const UPDATE_FACILITY_THRESHOLD_FAILURE =
  "UPDATE_FACILITY_THRESHOLD_FAILURE";
//---------------------------------STATE  CLEAR TYPES--------------------------------

export const CLEAR_ADMIN_BASELINE_STATE = "CLEAR_ADMIN_BASELINE_STATE";

//---------------------------------PERFORMANCE TYPES--------------------------------
export const CREATE_EMAIL_TEMPLATE_REQUEST = "CREATE_EMAIL_TEMPLATE_REQUEST";
export const CREATE_EMAIL_TEMPLATE_SUCCESS = "CREATE_EMAIL_TEMPLATE_SUCCESS";
export const CREATE_EMAIL_TEMPLATE_FAILURE = "CREATE_EMAIL_TEMPLATE_FAILURE";

export const GET_EMAIL_TEMPLATE_REQUEST = "GET_EMAIL_TEMPLATE_REQUEST";
export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const GET_EMAIL_TEMPLATE_FAILURE = "GET_EMAIL_TEMPLATE_FAILURE";

export const UPDATE_EMAIL_TEMPLATE_REQUEST = "UPDATE_EMAIL_TEMPLATE_REQUEST";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_FAILURE = "UPDATE_EMAIL_TEMPLATE_FAILURE";

export const DELETE_EMAIL_TEMPLATE_REQUEST = "DELETE_EMAIL_TEMPLATE_REQUEST";
export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS";
export const DELETE_EMAIL_TEMPLATE_FAILURE = "DELETE_EMAIL_TEMPLATE_FAILURE";

export const GET_INCENTIVE_SETTINGS_REQUEST = "GET_INCENTIVE_SETTINGS_REQUEST";
export const GET_INCENTIVE_SETTINGS_SUCCESS = "GET_INCENTIVE_SETTINGS_SUCCESS";
export const GET_INCENTIVE_SETTINGS_FAILURE = "GET_INCENTIVE_SETTINGS_FAILURE";

export const UPDATE_INCENTIVE_SETTINGS_REQUEST =
  "UPDATE_INCENTIVE_SETTINGS_REQUEST";
export const UPDATE_INCENTIVE_SETTINGS_SUCCESS =
  "UPDATE_INCENTIVE_SETTINGS_SUCCESS";
export const UPDATE_INCENTIVE_SETTINGS_FAILURE =
  "UPDATE_INCENTIVE_SETTINGS_FAILURE";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILURE = "CREATE_CONTACT_FAILURE";

export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAILURE = "GET_CONTACT_FAILURE";

export const UPDATE_CONTACT_REQUEST = "UPDATE_CONTACT_REQUEST";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILURE = "UPDATE_CONTACT_FAILURE";

export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";

export const GET_EMAIL_ARCHIVE_REQUEST = "GET_EMAIL_ARCHIVE_REQUEST";
export const GET_EMAIL_ARCHIVE_SUCCESS = "GET_EMAIL_ARCHIVE_SUCCESS";
export const GET_EMAIL_ARCHIVE_FAILURE = "GET_EMAIL_ARCHIVE_FAILURE";

// Admin Performance Actions---------------------------------
export const GET_ADMIN_BASELINE_DATA_SUMMARY_REQUEST =
  "GET_ADMIN_BASELINE_DATA_SUMMARY_REQUEST";
export const GET_ADMIN_BASELINE_DATA_SUMMARY_SUCCESS =
  "GET_ADMIN_BASELINE_DATA_SUMMARY_SUCCESS";
export const GET_ADMIN_BASELINE_DATA_SUMMARY_FAILURE =
  "GET_ADMIN_BASELINE_DATA_SUMMARY_FAILURE";

export const CREATE_ADMIN_NON_ROUTINE_EVENT_REQUEST =
  "CREATE_ADMIN_NON_ROUTINE_EVENT_REQUEST";
export const CREATE_ADMIN_NON_ROUTINE_EVENT_SUCCESS =
  "CREATE_ADMIN_NON_ROUTINE_EVENT_SUCCESS";
export const CREATE_ADMIN_NON_ROUTINE_EVENT_FAILURE =
  "CREATE_ADMIN_NON_ROUTINE_EVENT_FAILURE";

export const GET_ADMIN_NON_ROUTINE_EVENT_LIST_REQUEST =
  "GET_ADMIN_NON_ROUTINE_EVENT_LIST_REQUEST";
export const GET_ADMIN_NON_ROUTINE_EVENT_LIST_SUCCESS =
  "GET_ADMIN_NON_ROUTINE_EVENT_LIST_SUCCESS";
export const GET_ADMIN_NON_ROUTINE_EVENT_LIST_FAILURE =
  "GET_ADMIN_NON_ROUTINE_EVENT_LIST_FAILURE";

export const ADD_ADMIN_NON_ROUTINE_EVENT_DATA_REQUEST =
  "ADD_ADMIN_NON_ROUTINE_EVENT_DATA_REQUEST";
export const ADD_ADMIN_NON_ROUTINE_EVENT_DATA_SUCCESS =
  "ADD_ADMIN_NON_ROUTINE_EVENT_DATA_SUCCESS";
export const ADD_ADMIN_NON_ROUTINE_EVENT_DATA_FAILURE =
  "ADD_ADMIN_NON_ROUTINE_EVENT_DATA_FAILURE";

export const GET_ADMIN_NON_ROUTINE_EVENT_DETAIL_REQUEST =
  "GET_ADMIN_NON_ROUTINE_EVENT_DETAIL_REQUEST";
export const GET_ADMIN_NON_ROUTINE_EVENT_DETAIL_SUCCESS =
  "GET_ADMIN_NON_ROUTINE_EVENT_DETAIL_SUCCESS";
export const GET_ADMIN_NON_ROUTINE_EVENT_DETAIL_FAILURE =
  "GET_ADMIN_NON_ROUTINE_EVENT_DETAIL_FAILURE";

export const EDIT_ADMIN_NON_ROUTINE_EVENT_REQUEST =
  "EDIT_ADMIN_NON_ROUTINE_EVENT_REQUEST";
export const EDIT_ADMIN_NON_ROUTINE_EVENT_SUCCESS =
  "EDIT_ADMIN_NON_ROUTINE_EVENT_SUCCESS";
export const EDIT_ADMIN_NON_ROUTINE_EVENT_FAILURE =
  "EDIT_ADMIN_NON_ROUTINE_EVENT_FAILURE";

export const EDIT_ADMIN_NON_ROUTINE_EVENT_DATA_REQUEST =
  "EDIT_ADMIN_NON_ROUTINE_EVENT_DATA_REQUEST";
export const EDIT_ADMIN_NON_ROUTINE_EVENT_DATA_SUCCESS =
  "EDIT_ADMIN_NON_ROUTINE_EVENT_DATA_SUCCESS";
export const EDIT_ADMIN_NON_ROUTINE_EVENT_DATA_FAILURE =
  "EDIT_ADMIN_NON_ROUTINE_EVENT_DATA_FAILURE";

export const DELETE_ADMIN_NON_ROUTINE_EVENT_REQUEST =
  "DELETE_ADMIN_NON_ROUTINE_EVENT_REQUEST";
export const DELETE_ADMIN_NON_ROUTINE_EVENT_SUCCESS =
  "DELETE_ADMIN_NON_ROUTINE_EVENT_SUCCESS";
export const DELETE_ADMIN_NON_ROUTINE_EVENT_FAILURE =
  "DELETE_ADMIN_NON_ROUTINE_EVENT_FAILURE";

export const DELETE_ADMIN_NON_ROUTINE_EVENT_DATA_REQUEST =
  "DELETE_ADMIN_NON_ROUTINE_EVENT_DATA_REQUEST";
export const DELETE_ADMIN_NON_ROUTINE_EVENT_DATA_SUCCESS =
  "DELETE_ADMIN_NON_ROUTINE_EVENT_DATA_SUCCESS";
export const DELETE_ADMIN_NON_ROUTINE_EVENT_DATA_FAILURE =
  "DELETE_ADMIN_NON_ROUTINE_EVENT_DATA_FAILURE";

export const SCORE_ADMIN_PERFORMANCE_DATA_REQUEST =
  "SCORE_ADMIN_PERFORMANCE_DATA_REQUEST";
export const SCORE_ADMIN_PERFORMANCE_DATA_SUCCESS =
  "SCORE_ADMIN_PERFORMANCE_DATA_SUCCESS";
export const SCORE_ADMIN_PERFORMANCE_DATA_FAILURE =
  "SCORE_ADMIN_PERFORMANCE_DATA_FAILURE";

export const CALCULATE_ADMIN_PERFORMANCE_REPORT_REQUEST =
  "CALCULATE_ADMIN_PERFORMANCE_REPORT_REQUEST";
export const CALCULATE_ADMIN_PERFORMANCE_REPORT_SUCCESS =
  "CALCULATE_ADMIN_PERFORMANCE_REPORT_SUCCESS";
export const CALCULATE_ADMIN_PERFORMANCE_REPORT_FAILURE =
  "CALCULATE_ADMIN_PERFORMANCE_REPORT_FAILURE";

export const UPDATE_ADMIN_PERFORMANCE_REPORT_REQUEST =
  "UPDATE_ADMIN_PERFORMANCE_REPORT_REQUEST";
export const UPDATE_ADMIN_PERFORMANCE_REPORT_SUCCESS =
  "UPDATE_ADMIN_PERFORMANCE_REPORT_SUCCESS";
export const UPDATE_ADMIN_PERFORMANCE_REPORT_FAILURE =
  "UPDATE_ADMIN_PERFORMANCE_REPORT_FAILURE";

export const GET_ADMIN_PERFORMANCE_REPORT_REQUEST =
  "GET_ADMIN_PERFORMANCE_REPORT_REQUEST";
export const GET_ADMIN_PERFORMANCE_REPORT_SUCCESS =
  "GET_ADMIN_PERFORMANCE_REPORT_SUCCESS";
export const GET_ADMIN_PERFORMANCE_REPORT_FAILURE =
  "GET_ADMIN_PERFORMANCE_REPORT_FAILURE";

export const GET_ADMIN_PERFORMANCE_DATA_MIN_MAX_DATE_REQUEST =
  "GET_ADMIN_PERFORMANCE_DATA_MIN_MAX_DATE_REQUEST";
export const GET_ADMIN_PERFORMANCE_DATA_MIN_MAX_DATE_SUCCESS =
  "GET_ADMIN_PERFORMANCE_DATA_MIN_MAX_DATE_SUCCESS";
export const GET_ADMIN_PERFORMANCE_DATA_MIN_MAX_DATE_FAILURE =
  "GET_ADMIN_PERFORMANCE_DATA_MIN_MAX_DATE_FAILURE";

export const GET_ADMIN_PERFORMANCE_DATA_VISUALIZATION_REQUEST =
  "GET_ADMIN_PERFORMANCE_DATA_VISUALIZATION_REQUEST";
export const GET_ADMIN_PERFORMANCE_DATA_VISUALIZATION_SUCCESS =
  "GET_ADMIN_PERFORMANCE_DATA_VISUALIZATION_SUCCESS";
export const GET_ADMIN_PERFORMANCE_DATA_VISUALIZATION_FAILURE =
  "GET_ADMIN_PERFORMANCE_DATA_VISUALIZATION_FAILURE";

export const FETCH_ADMIN_PERFORMANCE_DATA_SUMMARY_REQUEST =
  "FETCH_ADMIN_PERFORMANCE_DATA_SUMMARY_REQUEST";
export const FETCH_ADMIN_PERFORMANCE_DATA_SUMMARY_SUCCESS =
  "FETCH_ADMIN_PERFORMANCE_DATA_SUMMARY_SUCCESS";
export const FETCH_ADMIN_PERFORMANCE_DATA_SUMMARY_FAILURE =
  "FETCH_ADMIN_PERFORMANCE_DATA_SUMMARY_FAILURE";

export const FETCH_ADMIN_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_REQUEST =
  "FETCH_ADMIN_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_REQUEST";
export const FETCH_ADMIN_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_SUCCESS =
  "FETCH_ADMIN_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_SUCCESS";
export const FETCH_ADMIN_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_FAILURE =
  "FETCH_ADMIN_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_FAILURE";

export const GET_ADMIN_QA_QC_CHECKLIST_REQUEST =
  "GET_ADMIN_QA_QC_CHECKLIST_REQUEST";
export const GET_ADMIN_QA_QC_CHECKLIST_SUCCESS =
  "GET_ADMIN_QA_QC_CHECKLIST_SUCCESS";
export const GET_ADMIN_QA_QC_CHECKLIST_FAILURE =
  "GET_ADMIN_QA_QC_CHECKLIST_FAILURE";

export const UPDATE_ADMIN_QA_QC_CHECKLIST_REQUEST =
  "UPDATE_ADMIN_QA_QC_CHECKLIST_REQUEST";
export const UPDATE_ADMIN_QA_QC_CHECKLIST_SUCCESS =
  "UPDATE_ADMIN_QA_QC_CHECKLIST_SUCCESS";
export const UPDATE_ADMIN_QA_QC_CHECKLIST_FAILURE =
  "UPDATE_ADMIN_QA_QC_CHECKLIST_FAILURE";

export const FETCH_ADMIN_BASELINE_PREDICTED_DATA_REQUEST =
  "FETCH_ADMIN_BASELINE_PREDICTED_DATA_REQUEST";
export const FETCH_ADMIN_BASELINE_PREDICTED_DATA_SUCCESS =
  "FETCH_ADMIN_BASELINE_PREDICTED_DATA_SUCCESS";
export const FETCH_ADMIN_BASELINE_PREDICTED_DATA_FAILURE =
  "FETCH_ADMIN_BASELINE_PREDICTED_DATA_FAILURE";

export const FETCH_ADMIN_PERFORMANCE_PREDICTED_DATA_REQUEST =
  "FETCH_ADMIN_PERFORMANCE_PREDICTED_DATA_REQUEST";
export const FETCH_ADMIN_PERFORMANCE_PREDICTED_DATA_SUCCESS =
  "FETCH_ADMIN_PERFORMANCE_PREDICTED_DATA_SUCCESS";
export const FETCH_ADMIN_PERFORMANCE_PREDICTED_DATA_FAILURE =
  "FETCH_ADMIN_PERFORMANCE_PREDICTED_DATA_FAILURE";
export const GET_ADMIN_DYNAMIC_EMAIL_TEMPLATE_REQUEST =
  "GET_ADMIN_DYNAMIC_EMAIL_TEMPLATE_REQUEST";
export const GET_ADMIN_DYNAMIC_EMAIL_TEMPLATE_SUCCESS =
  "GET_ADMIN_DYNAMIC_EMAIL_TEMPLATE_SUCCESS";
export const GET_ADMIN_DYNAMIC_EMAIL_TEMPLATE_FAILURE =
  "GET_ADMIN_DYNAMIC_EMAIL_TEMPLATE_FAILURE";
