import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
  TextField,
  Select,
  MenuItem,
  Stack,
  InputLabel,
  FormControl,
  FormGroup,
} from "@mui/material";
import FacilityOverview from "./facilityOverview";
import FacilityApproved from "./facilityApproved";
import FacilityReview from "./facilityReview";
import FacilityRejected from "./facilityRejected";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import FacilityCreated from "./facilityCreated";
import EvModal from "utils/modal/EvModal";
import { Form, Formik } from "formik";
import InputField from "components/FormBuilder/InputField";
import ButtonWrapper from "components/FormBuilder/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  adminAssignFacilities,
  downloadFacilitiesBulkData,
  downloadFacilityRowData,
  fetchAdminFacilitiesDropdown,
} from "../../../redux/admin/actions/adminFacilityActions";
import { validationSchemaAssignFacility } from "utils/validations/formValidation";
import {
  fetchAdminCompaniesDropdown,
  fetchAdminCompanyDetails,
} from "../../../redux/admin/actions/adminCompanyAction";
import NotificationsToast from "utils/notification/NotificationsToast";
import Loader from "pages/Loader";
import RequestToJoinModal from "./RequestToJoinModal";

const AdminFacilityListing = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("overview");
  const [companyFilter, setCompanyFilter] = useState("");
  const [pageInfo, setPageInfo] = useState({ page: 1, pageSize: 10 });
  const [facilityDropdownData, setFacilityDropdownData] = useState([]);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    dispatch(fetchAdminFacilitiesDropdown());
    dispatch(fetchAdminCompaniesDropdown());
  }, [dispatch]);

  const adminCompaniesDropdownData = useSelector(
    (state) => state?.adminCompanyReducer?.companiesDropdown?.data || []
  );
  const loadingState = useSelector(
    (state) => state?.adminFacilityReducer?.loading
  );
  const alertLoadingState = useSelector(
    (state) => state?.adminCompanyReducer?.loading
  );
  const emailToAvoid = useSelector(
    (state) => state?.facilityReducer?.userDetails?.user?.email || ""
  );

  const onDownloadBulkClick = (page_info, status) => {
    dispatch(downloadFacilitiesBulkData(page_info, companyFilter, status)).then(
      (response) => {
        const data = response?.data;
        if (data) {
          fetch(data)
            .then((res) => {
              res.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                let fileName = "Facilities_Bulk_Data.csv";
                alink.download = fileName;
                alink.click();
              });
              NotificationsToast({
                message: "Downloading facilities bulk data completed",
                type: "success",
              });
            })
            .catch((error) => {
              NotificationsToast({
                message: error?.message
                  ? error.message
                  : "Something went wrong while downloading!",
                type: "error",
              });
            });
        }
      }
    );
  };

  const onDownloadRowClick = (facility_id, facility_name) => {
    dispatch(downloadFacilityRowData(facility_id)).then((response) => {
      const data = response?.data;
      if (data) {
        fetch(data).then((res) => {
          res
            .blob()
            .then((blob) => {
              const fileURL = window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              let fileName = "Facility_Data";
              if (facility_name) {
                fileName += "_for_" + facility_name + ".csv";
              }
              alink.download = fileName;
              alink.click();
              NotificationsToast({
                message: `Downloading facility data for ${facility_name} completed`,
                type: "success",
              });
            })
            .catch((error) => {
              NotificationsToast({
                message: error?.message
                  ? error.message
                  : "Something went wrong while downloading!",
                type: "error",
              });
            });
        });
      }
    });
  };
  
  const [modalConfig, setModalConfig] = useState({
    modalVisible: false,
    modalUI: {
      showHeader: true,
      crossIcon: false,
      modalClass: "",
      headerTextStyle: { color: "rgba(84, 88, 90, 1)" },
      headerSubTextStyle: {
        marginTop: "1rem",
        color: "rgba(36, 36, 36, 1)",
        fontSize: { md: "0.875rem" },
      },
      fotterActionStyle: "",
      modalBodyContentStyle: "",
    },
    buttonsUI: {
      saveButton: false,
      cancelButton: false,
      saveButtonName: "Sent Request",
      cancelButtonName: "Cancel",
      successButtonStyle: {},
      cancelButtonStyle: {},
      saveButtonClass: "",
      cancelButtonClass: "",
    },
    headerText: "Assign Facility",
    headerSubText: "",
    modalBodyContent: "",
  });

  const openRequestModal = () => {
    setModalConfig((prevState) => ({
      ...prevState,
      modalVisible: true,
      modalBodyContent: <RequestToJoinModal setModalConfig={setModalConfig} />,
    }));
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case "overview":
        return <FacilityOverview searchVal={searchString} />;
      case "created_facilities":
        return (
          <FacilityCreated
            searchVal={searchString}
            companyFilter={companyFilter}
            onDownloadBulkClick={onDownloadBulkClick}
            onDownloadRowClick={onDownloadRowClick}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
          />
        );
      case "approved":
        return (
          <FacilityApproved
            searchVal={searchString}
            companyFilter={companyFilter}
            onDownloadBulkClick={onDownloadBulkClick}
            onDownloadRowClick={onDownloadRowClick}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
          />
        );
      case "underreview":
        return (
          <FacilityReview
            searchVal={searchString}
            companyFilter={companyFilter}
            onDownloadBulkClick={onDownloadBulkClick}
            onDownloadRowClick={onDownloadRowClick}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
          />
        );
      case "rejected":
        return (
          <FacilityRejected
            searchVal={searchString}
            companyFilter={companyFilter}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={100} md={4}>
          <Typography
            variant="h4"
            sx={{ fontSize: "1.5rem", color: "text.secondary2" }}
          >
            Facilities Management
          </Typography>
          {/* <Typography variant="small2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Typography> */}
        </Grid>
        <Grid item xs={100} md={8}>
          <Grid
            container
            alignItems="center"
            sx={{
              gap: "2rem",
              justifyContent: { xs: "flex-start", md: "flex-end" },
            }}
          >
            <Grid item>
              <TextField
                name="search"
                label="Search by facility name"
                type="text"
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    height: "2.9rem",
                    borderRadius: "6px",
                  },
                }}
                onChange={(e) => {
                  setSearchString(e.target.value);
                  setPageInfo({ page: 1, pageSize: 10 });
                }}
              />
            </Grid>
            <Grid
              item
              sm={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FormGroup className="theme-form-group theme-select-form-group">
                <FormControl sx={{ minWidth: "6rem" }}>
                  <Select
                    displayEmpty={true}
                    className="transparent-border"
                    value={companyFilter}
                    onChange={(e) => setCompanyFilter(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Company name</em>
                    </MenuItem>
                    {adminCompaniesDropdownData?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.company_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid
              item
              // sm={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="contained"
                sx={{
                  padding: "4px 12px",
                  minWidth: "5rem!important",
                  // bgcolor: "#2C77E9",
                }}
                onClick={openRequestModal}
              >
                Assign Access
              </Button>
            </Grid>
            <Grid
              item
              // sm={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                style={{
                  backgroundColor: "transparent",
                  padding: 0,
                  minWidth: "unset",
                  fontSize: "0.875rem",
                }}
                disableRipple
                endIcon={
                  <AddCircleIcon
                    style={{
                      color: "text.primary",
                      fontSize: "2rem",
                    }}
                  />
                }
                onClick={() => navigate("/facility-list/add-facility")}
              >
                Add Facility
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div container>
        <Grid
          item
          xs={12}
          md={8}
          mt={4}
          sx={{
            overflowX: "auto!important",
            ".MuiTabs-scroller": {
              overflowX: { xs: "auto !important", md: "hidden" },
            },
          }}
        >
          <Tabs
            className="theme-tabs-list"
            value={tabValue}
            onChange={handleChange}
            sx={{
              display: "inline-flex",
            }}
          >
            <Tab value="overview" label="Overview" sx={{ minWidth: "10rem" }} />
            <Tab
              value="created_facilities"
              label="Created facilities"
              sx={{ minWidth: "10rem" }}
            />

            <Tab value="approved" label="Approved" sx={{ minWidth: "10rem" }} />
            <Tab
              value="underreview"
              label="Under Review"
              sx={{ minWidth: "10rem" }}
            />
            <Tab value="rejected" label="Rejected" sx={{ minWidth: "10rem" }} />
          </Tabs>
        </Grid>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderTabContent()}
        </Grid>
      </Grid>
      <EvModal modalConfig={modalConfig} setModalConfig={setModalConfig} />
      <Loader
        sectionLoader
        minHeight="100vh"
        loadingState={loadingState || alertLoadingState}
        loaderPosition="fixed"
      />
    </Container>
  );
};

export default AdminFacilityListing;
