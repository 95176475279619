//---------------------------------FILE TYPES--------------------------------
export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";

//--------------------------------LOADER-------------------------------------
export const SHOW_LOADER = "SHOW_LOADER";
export const SHOW_EV_PAGE_LOADER = "SHOW_EV_PAGE_LOADER";

//---------------------------------EXPORT FILE TYPES--------------------------------

export const FETCH_EXPORT_FILE_RECORD_ID_REQUEST =
  "FETCH_EXPORT_FILE_RECORD_ID_REQUEST";
export const FETCH_EXPORT_FILE_RECORD_ID_SUCCESS =
  "FETCH_EXPORT_FILE_RECORD_ID_SUCCESS";
export const FETCH_EXPORT_FILE_RECORD_ID_FAILURE =
  "FETCH_EXPORT_FILE_RECORD_ID_FAILURE";

export const FETCH_EXPORT_STATUS_REQUEST = "FETCH_EXPORT_STATUS_REQUEST";
export const FETCH_EXPORT_STATUS_SUCCESS = "FETCH_EXPORT_STATUS_SUCCESS";
export const FETCH_EXPORT_STATUS_FAILURE = "FETCH_EXPORT_STATUS_FAILURE";

export const FETCH_UNREAD_NOTIFICATIONS_REQUEST =
  "FETCH_UNREAD_NOTIFICATIONS_REQUEST";
export const FETCH_UNREAD_NOTIFICATIONS_SUCCESS =
  "FETCH_UNREAD_NOTIFICATIONS_SUCCESS";
export const FETCH_UNREAD_NOTIFICATIONS_FAILURE =
  "FETCH_UNREAD_NOTIFICATIONS_FAILURE";

export const MARK_NOTIFICATION_AS_READ_REQUEST =
  "MARK_NOTIFICATION_AS_READ_REQUEST";
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_FAILURE =
  "MARK_NOTIFICATION_AS_READ_FAILURE";

export const CLEAR_NOTIFICATION_STATE = "CLEAR_NOTIFICATION_STATE";
