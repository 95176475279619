export const NUMBER_OF_ARRAY_2 = [
  { value: 1, label: "Year round" },
  { value: 2, label: "Seasonal" },
];

export const FLOOR_AREA_ARRAY = [
  {
    label: "Less than 10000",
    value: 1,
  },
  {
    label: "10000 - 49999",
    value: 2,
  },
  {
    label: "50000 - 99999",
    value: 3,
  },
  {
    label: "100000 - 249999",
    value: 4,
  },
  {
    label: "250000 - 499999",
    value: 5,
  },
  {
    label: "500000 - 999999",
    value: 6,
  },
  {
    label: "Greater that 1000000",
    value: 7,
  },
];

export const SOURCE_ARRAY = [
  {
    label: "Electricity",
    value: "electricity",
  },
  {
    label: "Natural gas",
    value: "natural_gas",
  },
  { label: "Other", value: "other" },
];

export const SPACE_COOLING_ARRAY = [
  { value: "chiller", label: "Chiller" },
  {
    value: "rooftop_unit",
    label: "Rooftop unit",
  },
  {
    value: "window_air_conditioner",
    label: "Window air conditioner",
  },
  {
    value: "ground_source_heat_pump",
    label: "Ground source heat pump",
  },
  {
    value: "air_source_heat_pump_vrf",
    label: "Air source heat pump/VRF",
  },
  { value: "other", label: "Other" },
];

export const SPACE_HEATING_ARRAY = [
  { value: "boiler", label: "Boiler" },
  { value: "furnace", label: "Furnace" },
  { value: "radiator", label: "Radiator" },
  {
    value: "baseboard",
    label: "Baseboard",
  },
  {
    value: "ground_source_heat_pump",
    label: "Ground source heat pump",
  },
  {
    value: "air_source_heat_pump_VRF",
    label: "Air source heat pump/VRF",
  },
  { value: "other", label: "Other" },
];

export const WATER_HEATING_ARRAY = [
  { value: "boiler", label: "Boiler" },
  {
    value: "water_heater",
    label: "Water heater",
  },
  { value: "other", label: "Other" },
];

export const SPACE_COOLING_UNIT_ARRAY = [
  { value: "EER", label: "EER" },
  {
    value: "SEER",
    label: "SEER",
  },
  { value: "COP", label: "COP" },
];
export const SPACE_HEATING_UNIT_ARRAY = [
  { value: "%", label: "%" },
  {
    value: "HSPF",
    label: "HSPF",
  },
  { value: "COP", label: "COP" },
];
export const WATER_HEATING_UNIT_ARRAY = [
  { value: "%", label: "%" },
  { value: "COP", label: "COP" },
];

export const FACILITY_CATEGORY_ARRAY = [
  { id: "1", value: "Commercial" },
  {
    id: "2",
    value: "Institutional",
  },
  {
    id: "3",
    value: "Multi-Family",
  },
  { id: "4", value: "Industrial" },
];

export const NATURAL_GAS_UNIT_ARRAY = [
  { value: "cf", label: "cf (cubic feet)" },
  { value: "cm", label: "cm (cubic meter)" },
  { value: "GJ", label: "GJ" },
  { value: "kBtu", label: "kBtu (thousand Btu)" },
];

export const ELECTRICITY_UNIT_ARRAY = [{ value: "kWh", label: "kWh" }];

export const WATER_UNIT_ARRAY = [
  { value: "cu f", label: "cu f" },
  { value: "cu m", label: "cu m" },
];

export const MEASURE_REPORT_CATEGORY = [
  { value: "behavioral", label: "Behavioral" },
  { value: "capital", label: "Capital" },
  { value: "fuelSwitching", label: "Fuel-switching" },
  { value: "onSiteGeneration", label: "On-site Generation" },
  { value: "operational", label: "Operational" },
];

export const DOCUMENT_CATEGORY = [
  { label: "Study Report", value: "Study Report" },
  { label: "Design Report", value: "Design Report" },
  { label: "Drawing", value: "Drawing" },
  { label: "Invoice", value: "Invoice" },
  { label: "Quotation", value: "Quotation" },
  { label: "Certification", value: "Certification" },
  { label: "Photo", value: "Photo" },
];

export const HEATING_BALANCE_UNIT_ARRAY = [
  { value: "C", label: "C" },
  { value: "K", label: "K" },
];

export const COOLING_BALANCE_UNIT_ARRAY = [
  { value: "C", label: "C" },
  { value: "K", label: "K" },
];
