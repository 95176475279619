import React from "react";
import CompanyManageUserAccess from "sections/Admin/CompanyManagement/CompanyManageUserAccess";
import CompanyManageUserAccessOld from "sections/Admin/CompanyManagement/CompanyManageUserAccessOld";

const CompanyManageAccess = () => {
  return (
    <>
      <CompanyManageUserAccess />
      {/* <CompanyManageUserAccessOld /> */}
    </>
  );
};

export default CompanyManageAccess;
