export const logoStyle = {
  width: "100px",
  height: "auto",
  cursor: "pointer",
};

export const headingStyleInAccordion = {
  borderRadius: "138.875rem",
  border: "1px solid #D0D0D0",
  background: " #EBEBEB",
  padding: "0.375rem 1rem",
  color: "#696969",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  width: "max-content",
  marginBlockEnd: "2rem",
};
