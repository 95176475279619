//---------------------------------FACILITY TYPES--------------------------------

export const FETCH_FACILITY_LIST_REQUEST = "FETCH_FACILITY_LIST_REQUEST";
export const FETCH_FACILITY_LIST_SUCCESS = "FETCH_FACILITY_LIST_SUCCESS";
export const FETCH_FACILITY_LIST_FAILURE = "FETCH_FACILITY_LIST_FAILURE";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const SUBMIT_FACILITY_FOR_APPROVAL_REQUEST =
  "SUBMIT_FACILITY_FOR_APPROVAL_REQUEST";
export const SUBMIT_FACILITY_FOR_APPROVAL_SUCCESS =
  "SUBMIT_FACILITY_FOR_APPROVAL_SUCCESS";
export const SUBMIT_FACILITY_FOR_APPROVAL_FAILURE =
  "SUBMIT_FACILITY_FOR_APPROVAL_FAILURE";

export const FETCH_FACILITY_DETAILS_REQUEST = "FETCH_FACILITY_DETAILS_REQUEST";
export const FETCH_FACILITY_DETAILS_SUCCESS = "FETCH_FACILITY_DETAILS_SUCCESS";
export const FETCH_FACILITY_DETAILS_FAILURE = "FETCH_FACILITY_DETAILS_FAILURE";

export const DELETE_FACILITY_REQUEST = "DELETE_FACILITY_REQUEST";
export const DELETE_FACILITY_SUCCESS = "DELETE_FACILITY_SUCCESS";
export const DELETE_FACILITY_FAILURE = "DELETE_FACILITY_FAILURE";

export const ADD_FACILITY_CHARACTERISTICS_REQUEST =
  "ADD_FACILITY_CHARACTERISTICS_REQUEST";
export const ADD_FACILITY_CHARACTERISTICS_SUCCESS =
  "ADD_FACILITY_CHARACTERISTICS_SUCCESS";
export const ADD_FACILITY_CHARACTERISTICS_FAILURE =
  "ADD_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_FACILITY_CHARACTERISTICS_REQUEST =
  "FETCH_FACILITY_CHARACTERISTICS_REQUEST";
export const FETCH_FACILITY_CHARACTERISTICS_SUCCESS =
  "FETCH_FACILITY_CHARACTERISTICS_SUCCESS";
export const FETCH_FACILITY_CHARACTERISTICS_FAILURE =
  "FETCH_FACILITY_CHARACTERISTICS_FAILURE";

export const UPDATE_FACILITY_CHARACTERISTICS_REQUEST =
  "UPDATE_FACILITY_CHARACTERISTICS_REQUEST";
export const UPDATE_FACILITY_CHARACTERISTICS_SUCCESS =
  "UPDATE_FACILITY_CHARACTERISTICS_SUCCESS";
export const UPDATE_FACILITY_CHARACTERISTICS_FAILURE =
  "UPDATE_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_FACILITY_STATUS_REQUEST = "FETCH_FACILITY_STATUS_REQUEST";
export const FETCH_FACILITY_STATUS_SUCCESS = "FETCH_FACILITY_STATUS_SUCCESS";
export const FETCH_FACILITY_STATUS_FAILURE = "FETCH_FACILITY_STATUS_FAILURE";

export const UPDATE_FACILITY_STATUS_REQUEST = "UPDATE_FACILITY_STATUS_REQUEST";
export const UPDATE_FACILITY_STATUS_SUCCESS = "UPDATE_FACILITY_STATUS_SUCCESS";
export const UPDATE_FACILITY_STATUS_FAILURE = "UPDATE_FACILITY_STATUS_FAILURE";

export const ASSIGN_FACILITIES_REQUEST = "ASSIGN_FACILITIES_REQUEST";
export const ASSIGN_FACILITIES_SUCCESS = "ASSIGN_FACILITIES_SUCCESS";
export const ASSIGN_FACILITIES_FAILURE = "ASSIGN_FACILITIES_FAILURE";

export const FETCH_FACILITIES_DROPDOWN_REQUEST =
  "FETCH_FACILITIES_DROPDOWN_REQUEST";
export const FETCH_FACILITIES_DROPDOWN_SUCCESS =
  "FETCH_FACILITIES_DROPDOWN_SUCCESS";
export const FETCH_FACILITIES_DROPDOWN_FAILURE =
  "FETCH_FACILITIES_DROPDOWN_FAILURE";

export const FETCH_FACILITY_MEASURE_REPORT_LIST_REQUEST =
  "FETCH_FACILITY_MEASURE_REPORT_LIST_REQUEST";
export const FETCH_FACILITY_MEASURE_REPORT_LIST_SUCCESS =
  "FETCH_FACILITY_MEASURE_REPORT_LIST_SUCCESS";
export const FETCH_FACILITY_MEASURE_REPORT_LIST_FAILURE =
  "FETCH_FACILITY_MEASURE_REPORT_LIST_FAILURE";

export const ADD_FACILITY_MEASURE_REPORT_REQUEST =
  "ADD_FACILITY_MEASURE_REPORT_REQUEST";
export const ADD_FACILITY_MEASURE_REPORT_SUCCESS =
  "ADD_FACILITY_MEASURE_REPORT_SUCCESS";
export const ADD_FACILITY_MEASURE_REPORT_FAILURE =
  "ADD_FACILITY_MEASURE_REPORT_FAILURE";

export const FETCH_FACILITY_MEASURE_REPORT_DETAILS_REQUEST =
  "FETCH_FACILITY_MEASURE_REPORT_DETAILS_REQUEST";
export const FETCH_FACILITY_MEASURE_REPORT_DETAILS_SUCCESS =
  "FETCH_FACILITY_MEASURE_REPORT_DETAILS_SUCCESS";
export const FETCH_FACILITY_MEASURE_REPORT_DETAILS_FAILURE =
  "FETCH_FACILITY_MEASURE_REPORT_DETAILS_FAILURE";

export const UPDATE_FACILITY_MEASURE_REPORT_REQUEST =
  "UPDATE_FACILITY_MEASURE_REPORT_REQUEST";
export const UPDATE_FACILITY_MEASURE_REPORT_SUCCESS =
  "UPDATE_FACILITY_MEASURE_REPORT_SUCCESS";
export const UPDATE_FACILITY_MEASURE_REPORT_FAILURE =
  "UPDATE_FACILITY_MEASURE_REPORT_FAILURE";

export const DELETE_FACILITY_MEASURE_REPORT_REQUEST =
  "DELETE_FACILITY_MEASURE_REPORT_REQUEST";
export const DELETE_FACILITY_MEASURE_REPORT_SUCCESS =
  "DELETE_FACILITY_MEASURE_REPORT_SUCCESS";
export const DELETE_FACILITY_MEASURE_REPORT_FAILURE =
  "DELETE_FACILITY_MEASURE_REPORT_FAILURE";

export const FETCH_FACILITY_DOCUMENT_LIST_REQUEST =
  "FETCH_FACILITY_DOCUMENT_LIST_REQUEST";
export const FETCH_FACILITY_DOCUMENT_LIST_SUCCESS =
  "FETCH_FACILITY_DOCUMENT_LIST_SUCCESS";
export const FETCH_FACILITY_DOCUMENT_LIST_FAILURE =
  "FETCH_FACILITY_DOCUMENT_LIST_FAILURE";

export const ADD_FACILITY_DOCUMENT_REQUEST = "ADD_FACILITY_DOCUMENT_REQUEST";
export const ADD_FACILITY_DOCUMENT_SUCCESS = "ADD_FACILITY_DOCUMENT_SUCCESS";
export const ADD_FACILITY_DOCUMENT_FAILURE = "ADD_FACILITY_DOCUMENT_FAILURE";

export const FETCH_FACILITY_DOCUMENT_DETAILS_REQUEST =
  "FETCH_FACILITY_DOCUMENT_DETAILS_REQUEST";
export const FETCH_FACILITY_DOCUMENT_DETAILS_SUCCESS =
  "FETCH_FACILITY_DOCUMENT_DETAILS_SUCCESS";
export const FETCH_FACILITY_DOCUMENT_DETAILS_FAILURE =
  "FETCH_FACILITY_DOCUMENT_DETAILS_FAILURE";

export const UPDATE_FACILITY_DOCUMENT_REQUEST =
  "UPDATE_FACILITY_DOCUMENT_REQUEST";
export const UPDATE_FACILITY_DOCUMENT_SUCCESS =
  "UPDATE_FACILITY_DOCUMENT_SUCCESS";
export const UPDATE_FACILITY_DOCUMENT_FAILURE =
  "UPDATE_FACILITY_DOCUMENT_FAILURE";

export const DELETE_FACILITY_DOCUMENT_REQUEST =
  "DELETE_FACILITY_DOCUMENT_REQUEST";
export const DELETE_FACILITY_DOCUMENT_SUCCESS =
  "DELETE_FACILITY_DOCUMENT_SUCCESS";
export const DELETE_FACILITY_DOCUMENT_FAILURE =
  "DELETE_FACILITY_DOCUMENT_FAILURE";

export const SEND_HELP_REQ_FOR_MEASURE_REQUEST =
  "SEND_HELP_REQ_FOR_MEASURE_REQUEST";
export const SEND_HELP_REQ_FOR_MEASURE_SUCCESS =
  "SEND_HELP_REQ_FOR_MEASURE_SUCCESS";
export const SEND_HELP_REQ_FOR_MEASURE_FAILURE =
  "SEND_HELP_REQ_FOR_MEASURE_FAILURE";

export const GET_WATERFALL_DATA_REQUEST = "GET_WATERFALL_DATA_REQUEST";
export const GET_WATERFALL_DATA_SUCCESS = "GET_WATERFALL_DATA_SUCCESS";
export const GET_WATERFALL_DATA_FAILURE = "GET_WATERFALL_DATA_FAILURE";

//---------------------------------METER TYPES--------------------------------
export const FETCH_METER_LIST_REQUEST = "FETCH_METER_LIST_REQUEST";
export const FETCH_METER_LIST_SUCCESS = "FETCH_METER_LIST_SUCCESS";
export const FETCH_METER_LIST_FAILURE = "FETCH_METER_LIST_FAILURE";

export const ADD_METER_REQUEST = "ADD_METER_REQUEST";
export const ADD_METER_SUCCESS = "ADD_METER_SUCCESS";
export const ADD_METER_FAILURE = "ADD_METER_FAILURE";

export const UPDATE_METER_REQUEST = "UPDATE_METER_REQUEST";
export const UPDATE_METER_SUCCESS = "UPDATE_METER_SUCCESS";
export const UPDATE_METER_FAILURE = "UPDATE_METER_FAILURE";

export const FETCH_METER_DETAILS_REQUEST = "FETCH_METER_DETAILS_REQUEST";
export const FETCH_METER_DETAILS_SUCCESS = "FETCH_METER_DETAILS_SUCCESS";
export const FETCH_METER_DETAILS_FAILURE = "FETCH_METER_DETAILS_FAILURE";

export const DELETE_METER_REQUEST = "DELETE_METER_REQUEST";
export const DELETE_METER_SUCCESS = "DELETE_METER_SUCCESS";
export const DELETE_METER_FAILURE = "DELETE_METER_FAILURE";

export const FETCH_METER_STATISTICS_REQUEST = "FETCH_METER_STATISTICS_REQUEST";
export const FETCH_METER_STATISTICS_SUCCESS = "FETCH_METER_STATISTICS_SUCCESS";
export const FETCH_METER_STATISTICS_FAILURE = "FETCH_METER_STATISTICS_FAILURE";
//---------------------------------ENTRIES TYPES--------------------------------
export const FETCH_ENTRIES_LIST_REQUEST = "FETCH_ENTRIES_LIST_REQUEST";
export const FETCH_ENTRIES_LIST_SUCCESS = "FETCH_ENTRIES_LIST_SUCCESS";
export const FETCH_ENTRIES_LIST_FAILURE = "FETCH_ENTRIES_LIST_FAILURE";

export const SHOW_LOADER = "SHOW_LOADER";

//---------------------------------BASELINE TYPES--------------------------------
export const SUFFICIENCY_CHECK_REQUEST = "SUFFICIENCY_CHECK_REQUEST";
export const SUFFICIENCY_CHECK_SUCCESS = "SUFFICIENCY_CHECK_SUCCESS";
export const SUFFICIENCY_CHECK_FAILURE = "SUFFICIENCY_CHECK_FAILURE";

export const INDEPENDENT_VARIABLE_LIST_REQUEST =
  "INDEPENDENT_VARIABLE_LIST_REQUEST";
export const INDEPENDENT_VARIABLE_LIST_SUCCESS =
  "INDEPENDENT_VARIABLE_LIST_SUCCESS";
export const INDEPENDENT_VARIABLE_LIST_FAILURE =
  "INDEPENDENT_VARIABLE_LIST_FAILURE";

export const FETCH_BASELINE_PERIOD_REQUEST = "FETCH_BASELINE_PERIOD_REQUEST";
export const FETCH_BASELINE_PERIOD_SUCCESS = "FETCH_BASELINE_PERIOD_SUCCESS";
export const FETCH_BASELINE_PERIOD_FAILURE = "FETCH_BASELINE_PERIOD_FAILURE";

export const FETCH_STATIONS_DETAILS_REQUEST = "FETCH_STATIONS_DETAILS_REQUEST";
export const FETCH_STATIONS_DETAILS_SUCCESS = "FETCH_STATIONS_DETAILS_SUCCESS";
export const FETCH_STATIONS_DETAILS_FAILURE = "FETCH_STATIONS_DETAILS_FAILURE";

export const FETCH_ISSUE_DETAILS_REQUEST = "FETCH_ISSUE_DETAILS_REQUEST";
export const FETCH_ISSUE_DETAILS_SUCCESS = "FETCH_ISSUE_DETAILS_SUCCESS";
export const FETCH_ISSUE_DETAILS_FAILURE = "FETCH_ISSUE_DETAILS_FAILURE";

export const ADD_BASELINE_DB_REQUEST = "ADD_BASELINE_DB_REQUEST";
export const ADD_BASELINE_DB_SUCCESS = "ADD_BASELINE_DB_SUCCESS";
export const ADD_BASELINE_DB_FAILURE = "ADD_BASELINE_DB_FAILURE";

export const FETCH_BASELINE_DETAILS_DB_REQUEST =
  "FETCH_BASELINE_DETAILS_DB_REQUEST";
export const FETCH_BASELINE_DETAILS_DB_SUCCESS =
  "FETCH_BASELINE_DETAILS_DB_SUCCESS";
export const FETCH_BASELINE_DETAILS_DB_FAILURE =
  "FETCH_BASELINE_DETAILS_DB_FAILURE";

export const UPDATE_BASELINE_DETAILS_DB_REQUEST =
  "UPDATE_BASELINE_DETAILS_DB_REQUEST";
export const UPDATE_BASELINE_DETAILS_DB_SUCCESS =
  "UPDATE_BASELINE_DETAILS_DB_SUCCESS";
export const UPDATE_BASELINE_DETAILS_DB_FAILURE =
  "UPDATE_BASELINE_DETAILS_DB_FAILURE";

export const FETCH_BASELINE_LIST_DB_REQUEST = "FETCH_BASELINE_LIST_DB_REQUEST";
export const FETCH_BASELINE_LIST_DB_SUCCESS = "FETCH_BASELINE_LIST_DB_SUCCESS";
export const FETCH_BASELINE_LIST_DB_FAILURE = "FETCH_BASELINE_LIST_DB_FAILURE";

export const ADD_ASSIGNEE_TO_BASELINE_DB_REQUEST =
  "ADD_ASSIGNEE_TO_BASELINE_DB_REQUEST";
export const ADD_ASSIGNEE_TO_BASELINE_DB_SUCCESS =
  "ADD_ASSIGNEE_TO_BASELINE_DB_SUCCESS";
export const ADD_ASSIGNEE_TO_BASELINE_DB_FAILURE =
  "ADD_ASSIGNEE_TO_BASELINE_DB_FAILURE";

export const SUBMIT_REJECTED_BASELINE_DB_REQUEST =
  "SUBMIT_REJECTED_BASELINE_DB_REQUEST";
export const SUBMIT_REJECTED_BASELINE_DB_SUCCESS =
  "SUBMIT_REJECTED_BASELINE_DB_SUCCESS";
export const SUBMIT_REJECTED_BASELINE_DB_FAILURE =
  "SUBMIT_REJECTED_BASELINE_DB_FAILURE";

export const SHOW_OBSERVE_DATA_REQUEST = "SHOW_OBSERVE_DATA_REQUEST";
export const SHOW_OBSERVE_DATA_SUCCESS = "SHOW_OBSERVE_DATA_SUCCESS";
export const SHOW_OBSERVE_DATA_FAILURE = "SHOW_OBSERVE_DATA_FAILURE";

export const SUBMIT_BASELINE_D_T_REQUEST = "SUBMIT_BASELINE_D_T_REQUEST";
export const SUBMIT_BASELINE_D_T_SUCCESS = "SUBMIT_BASELINE_D_T_SUCCESS";
export const SUBMIT_BASELINE_D_T_FAILURE = "SUBMIT_BASELINE_D_T_FAILURE";

export const FETCH_DATA_EXPLORATION_SUMMARY_REQUEST =
  "FETCH_DATA_EXPLORATION_SUMMARY_REQUEST";
export const FETCH_DATA_EXPLORATION_SUMMARY_SUCCESS =
  "FETCH_DATA_EXPLORATION_SUMMARY_SUCCESS";
export const FETCH_DATA_EXPLORATION_SUMMARY_FAILURE =
  "FETCH_DATA_EXPLORATION_SUMMARY_FAILURE";

export const FETCH_RAW_SUMMARY_METER_LIST_REQUEST =
  "FETCH_RAW_SUMMARY_METER_LIST_REQUEST";
export const FETCH_RAW_SUMMARY_METER_LIST_SUCCESS =
  "FETCH_RAW_SUMMARY_METER_LIST_SUCCESS";
export const FETCH_RAW_SUMMARY_METER_LIST_FAILURE =
  "FETCH_RAW_SUMMARY_METER_LIST_FAILURE";

export const FETCH_OUTLIERS_SETTINGS_REQUEST =
  "FETCH_OUTLIERS_SETTINGS_REQUEST";
export const FETCH_OUTLIERS_SETTINGS_SUCCESS =
  "FETCH_OUTLIERS_SETTINGS_SUCCESS";
export const FETCH_OUTLIERS_SETTINGS_FAILURE =
  "FETCH_OUTLIERS_SETTINGS_FAILURE";

export const GET_BASELINE_DATA_SUMMARY_REQUEST =
  "GET_BASELINE_DATA_SUMMARY_REQUEST";
export const GET_BASELINE_DATA_SUMMARY_SUCCESS =
  "GET_BASELINE_DATA_SUMMARY_SUCCESS";
export const GET_BASELINE_DATA_SUMMARY_FAILURE =
  "GET_BASELINE_DATA_SUMMARY_FAILURE";

export const CREATE_NON_ROUTINE_EVENT_REQUEST =
  "CREATE_NON_ROUTINE_EVENT_REQUEST";
export const CREATE_NON_ROUTINE_EVENT_SUCCESS =
  "CREATE_NON_ROUTINE_EVENT_SUCCESS";
export const CREATE_NON_ROUTINE_EVENT_FAILURE =
  "CREATE_NON_ROUTINE_EVENT_FAILURE";

export const GET_NON_ROUTINE_EVENT_LIST_REQUEST =
  "GET_NON_ROUTINE_EVENT_LIST_REQUEST";
export const GET_NON_ROUTINE_EVENT_LIST_SUCCESS =
  "GET_NON_ROUTINE_EVENT_LIST_SUCCESS";
export const GET_NON_ROUTINE_EVENT_LIST_FAILURE =
  "GET_NON_ROUTINE_EVENT_LIST_FAILURE";

export const ADD_NON_ROUTINE_EVENT_DATA_REQUEST =
  "ADD_NON_ROUTINE_EVENT_DATA_REQUEST";
export const ADD_NON_ROUTINE_EVENT_DATA_SUCCESS =
  "ADD_NON_ROUTINE_EVENT_DATA_SUCCESS";
export const ADD_NON_ROUTINE_EVENT_DATA_FAILURE =
  "ADD_NON_ROUTINE_EVENT_DATA_FAILURE";

export const GET_NON_ROUTINE_EVENT_DETAIL_REQUEST =
  "GET_NON_ROUTINE_EVENT_DETAIL_REQUEST";
export const GET_NON_ROUTINE_EVENT_DETAIL_SUCCESS =
  "GET_NON_ROUTINE_EVENT_DETAIL_SUCCESS";
export const GET_NON_ROUTINE_EVENT_DETAIL_FAILURE =
  "GET_NON_ROUTINE_EVENT_DETAIL_FAILURE";

export const EDIT_NON_ROUTINE_EVENT_REQUEST = "EDIT_NON_ROUTINE_EVENT_REQUEST";
export const EDIT_NON_ROUTINE_EVENT_SUCCESS = "EDIT_NON_ROUTINE_EVENT_SUCCESS";
export const EDIT_NON_ROUTINE_EVENT_FAILURE = "EDIT_NON_ROUTINE_EVENT_FAILURE";

export const EDIT_NON_ROUTINE_EVENT_DATA_REQUEST =
  "EDIT_NON_ROUTINE_EVENT_DATA_REQUEST";
export const EDIT_NON_ROUTINE_EVENT_DATA_SUCCESS =
  "EDIT_NON_ROUTINE_EVENT_DATA_SUCCESS";
export const EDIT_NON_ROUTINE_EVENT_DATA_FAILURE =
  "EDIT_NON_ROUTINE_EVENT_DATA_FAILURE";

export const DELETE_NON_ROUTINE_EVENT_REQUEST =
  "DELETE_NON_ROUTINE_EVENT_REQUEST";
export const DELETE_NON_ROUTINE_EVENT_SUCCESS =
  "DELETE_NON_ROUTINE_EVENT_SUCCESS";
export const DELETE_NON_ROUTINE_EVENT_FAILURE =
  "DELETE_NON_ROUTINE_EVENT_FAILURE";

export const DELETE_NON_ROUTINE_EVENT_DATA_REQUEST =
  "DELETE_NON_ROUTINE_EVENT_DATA_REQUEST";
export const DELETE_NON_ROUTINE_EVENT_DATA_SUCCESS =
  "DELETE_NON_ROUTINE_EVENT_DATA_SUCCESS";
export const DELETE_NON_ROUTINE_EVENT_DATA_FAILURE =
  "DELETE_NON_ROUTINE_EVENT_DATA_FAILURE";

export const SCORE_PERFORMANCE_DATA_REQUEST = "SCORE_PERFORMANCE_DATA_REQUEST";
export const SCORE_PERFORMANCE_DATA_SUCCESS = "SCORE_PERFORMANCE_DATA_SUCCESS";
export const SCORE_PERFORMANCE_DATA_FAILURE = "SCORE_PERFORMANCE_DATA_FAILURE";

export const CALCULATE_PERFORMANCE_REPORT_REQUEST =
  "CALCULATE_PERFORMANCE_REPORT_REQUEST";
export const CALCULATE_PERFORMANCE_REPORT_SUCCESS =
  "CALCULATE_PERFORMANCE_REPORT_SUCCESS";
export const CALCULATE_PERFORMANCE_REPORT_FAILURE =
  "CALCULATE_PERFORMANCE_REPORT_FAILURE";

export const UPDATE_PERFORMANCE_REPORT_REQUEST =
  "UPDATE_PERFORMANCE_REPORT_REQUEST";
export const UPDATE_PERFORMANCE_REPORT_SUCCESS =
  "UPDATE_PERFORMANCE_REPORT_SUCCESS";
export const UPDATE_PERFORMANCE_REPORT_FAILURE =
  "UPDATE_PERFORMANCE_REPORT_FAILURE";

export const GET_PERFORMANCE_REPORT_REQUEST = "GET_PERFORMANCE_REPORT_REQUEST";
export const GET_PERFORMANCE_REPORT_SUCCESS = "GET_PERFORMANCE_REPORT_SUCCESS";
export const GET_PERFORMANCE_REPORT_FAILURE = "GET_PERFORMANCE_REPORT_FAILURE";

export const GET_PERFORMANCE_DATA_MIN_MAX_DATE_REQUEST =
  "GET_PERFORMANCE_DATA_MIN_MAX_DATE_REQUEST";
export const GET_PERFORMANCE_DATA_MIN_MAX_DATE_SUCCESS =
  "GET_PERFORMANCE_DATA_MIN_MAX_DATE_SUCCESS";
export const GET_PERFORMANCE_DATA_MIN_MAX_DATE_FAILURE =
  "GET_PERFORMANCE_DATA_MIN_MAX_DATE_FAILURE";

export const GET_PERFORMANCE_DATA_VISUALIZATION_REQUEST =
  "GET_PERFORMANCE_DATA_VISUALIZATION_REQUEST";
export const GET_PERFORMANCE_DATA_VISUALIZATION_SUCCESS =
  "GET_PERFORMANCE_DATA_VISUALIZATION_SUCCESS";
export const GET_PERFORMANCE_DATA_VISUALIZATION_FAILURE =
  "GET_PERFORMANCE_DATA_VISUALIZATION_FAILURE";

export const FETCH_PERFORMANCE_DATA_SUMMARY_REQUEST =
  "FETCH_PERFORMANCE_DATA_SUMMARY_REQUEST";
export const FETCH_PERFORMANCE_DATA_SUMMARY_SUCCESS =
  "FETCH_PERFORMANCE_DATA_SUMMARY_SUCCESS";
export const FETCH_PERFORMANCE_DATA_SUMMARY_FAILURE =
  "FETCH_PERFORMANCE_DATA_SUMMARY_FAILURE";

export const FETCH_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_REQUEST =
  "FETCH_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_REQUEST";
export const FETCH_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_SUCCESS =
  "FETCH_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_SUCCESS";
export const FETCH_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_FAILURE =
  "FETCH_PERFORMANCE_DATA_RAW_SUMMARY_METER_LIST_FAILURE";

export const FETCH_BASELINE_PREDICTED_DATA_REQUEST =
  "FETCH_BASELINE_PREDICTED_DATA_REQUEST";
export const FETCH_BASELINE_PREDICTED_DATA_SUCCESS =
  "FETCH_BASELINE_PREDICTED_DATA_SUCCESS";
export const FETCH_BASELINE_PREDICTED_DATA_FAILURE =
  "FETCH_BASELINE_PREDICTED_DATA_FAILURE";

export const FETCH_PERFORMANCE_PREDICTED_DATA_REQUEST =
  "FETCH_PERFORMANCE_PREDICTED_DATA_REQUEST";
export const FETCH_PERFORMANCE_PREDICTED_DATA_SUCCESS =
  "FETCH_PERFORMANCE_PREDICTED_DATA_SUCCESS";
export const FETCH_PERFORMANCE_PREDICTED_DATA_FAILURE =
  "FETCH_PERFORMANCE_PREDICTED_DATA_FAILURE";

//---------------------------------STATE  CLEAR TYPES--------------------------------

export const CLEAR_BASELINE_STATE = "CLEAR_BASELINE_STATE";
