import React from "react";
import CompanyListing from "sections/Admin/CompanyManagement/CompanyListing";
import CompanyListingOld from "sections/Admin/CompanyManagement/CompanyListingOld";

const CompanyList = () => {
  return (
    <>
      <CompanyListing />
      {/* <CompanyListingOld /> */}
    </>
  );
};

export default CompanyList;
