import React from "react";
import CompanyUserListing from "sections/Admin/CompanyManagement/CompanyUserListing";

const CompanyUserList = () => {
  return (
    <>
      <CompanyUserListing />
    </>
  );
};

export default CompanyUserList;
