import {
  Box,
  Button,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Grid,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useEffect, useState } from "react";
import Table from "components/Table";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMeter,
  fetchMeterListing,
  fetchMeterStatistics,
} from "../../../redux/superAdmin/actions/metersActions";
import { format } from "date-fns";
import EvModal from "utils/modal/EvModal";
import Loader from "pages/Loader";
import {
  fetchFacilityDetails,
  fetchFacilityStatus,
} from "../../../redux/superAdmin/actions/facilityActions";

const MeterListing = ({
  onAddButtonClick,
  onEntriesListClick,
  OnEditMeterButton,
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [pageInfo, setPageInfo] = useState({ page: 1, pageSize: 10 });
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  const [meterToDelete, setMeterToDelete] = useState("");

  useEffect(() => {
    dispatch(fetchMeterStatistics(id));
  }, [dispatch, id]);

  const meterStatistics = useSelector(
    (state) => state?.meterReducer?.meterStatistics?.data
  );
  const loadingState = useSelector((state) => state?.meterReducer?.loading);

  const METER_TYPE_ARRAY = [
    { id: 1, value: "Electricity" },
    { id: 2, value: "Water" },
    { id: 3, value: "Natural Gas" },
  ];
  const getMeterTypeValue = (array, meter_type) => {
    const foundType = array.find((type) => type.id === meter_type);
    return foundType ? foundType.value : null;
  };

  const handleDeleteMeter = (mId) => {
    if (mId) {
      dispatch(deleteMeter(mId))
        .then(() => {
          setModalConfig((prevState) => ({
            ...prevState,
            modalVisible: false,
          }));
          dispatch(fetchMeterListing(pageInfo, id));
          dispatch(fetchMeterStatistics(id));
          dispatch(fetchFacilityStatus(id));
          dispatch(fetchFacilityDetails(id));
        })
        .catch((error) => {
          console.error("Error deleting facility:", error);
        });
    }
  };

  const openDeleteMeterModal = (meterId) => {
    setMeterToDelete(meterId);
    setModalConfig((prevState) => ({
      ...prevState,
      modalVisible: true,
    }));
  };

  const [modalConfig, setModalConfig] = useState({
    modalVisible: false,
    modalUI: {
      showHeader: true,
      crossIcon: false,
      modalClass: "",
      headerTextStyle: { color: "rgba(84, 88, 90, 1)" },
      headerSubTextStyle: {
        marginTop: "1rem",
        color: "rgba(36, 36, 36, 1)",
        fontSize: { md: "0.875rem" },
      },
      fotterActionStyle: "",
      modalBodyContentStyle: "",
    },
    buttonsUI: {
      saveButton: true,
      cancelButton: true,
      saveButtonName: "Delete",
      cancelButtonName: "Cancel",
      saveButtonClass: "",
      cancelButtonClass: "",
      successButtonStyle: {
        backgroundColor: "danger.scarlet",
        "&:hover": { backgroundColor: "danger.colorCrimson" },
        color: "#fff",
      },
      cancelButtonStyle: {
        backgroundColor: "primary.main",
        "&:hover": { backgroundColor: "primary.mainDarkShade" },
        color: "#fff",
      },
    },
    headerText: "Delete Meter",
    headerSubText: "Are you sure you want to delete this meter?",
    modalBodyContent: "",
    saveButtonAction: handleDeleteMeter,
  });

  const columns = [
    {
      Header: "Meter name",
      accessor: "meter_name",
      accessorKey: "meter_name",
    },
    {
      Header: "Meter type",
      accessor: (item) => {
        return getMeterTypeValue(METER_TYPE_ARRAY, item?.meter_type);
      },
    },
    {
      Header: "Meter ID",
      accessor: "meter_id",
      accessorKey: "meter_id",
    },
    {
      Header: "Status",
      accessor: (item) => <>{item?.stil_in_use ? "Active" : "Inactive"}</>,
    },
    {
      Header: "Most recent update",
      accessor: (item) => (
        <>{item?.updated_at && format(item?.updated_at, "MM/dd/yyyy")}</>
      ),
      accessorKey: "updated_at",
    },
    {
      Header: "In use (inactive) date",
      accessor: (item) => (
        <>
          {!item?.stil_in_use &&
            item?.meter_inactive &&
            format(item?.meter_inactive, "MM/dd/yyyy")}
        </>
      ),
      accessorKey: "meter_inactive",
    },
    {
      Header: "Actions",
      accessor: (item) => (
        <Box
          display="flex"
          onClick={(e) => e.stopPropagation()}
          justifyContent="flex-end"
        >
          <Button
            disableRipple
            style={{
              backgroundColor: "transparent",
              padding: 0,
              minWidth: "unset",
              fontSize: "0.875rem",
              textWrap: "nowrap",
            }}
            onClick={() => handleEntriesListClick(item.id, item?.meter_id)}
          >
            Add data
          </Button>
          <Button
            disableRipple
            style={{
              backgroundColor: "transparent",
              padding: 0,
              minWidth: "unset",
              marginLeft: "1rem",
              fontSize: "0.875rem",
              color: "#027397",
            }}
            onClick={() => handleEditButtonClick(item.id)}
          >
            Edit
          </Button>
          <Button
            color="error"
            disableRipple
            style={{
              backgroundColor: "transparent",
              padding: 0,
              minWidth: "unset",
              marginLeft: "1rem",
              fontSize: "0.875rem",
            }}
            onClick={() => openDeleteMeterModal(item.id)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  const meterListingData = useSelector(
    (state) => state?.meterReducer?.meterList?.data?.rows || []
  );
  const meterCount = useSelector(
    (state) => state?.meterReducer?.meterList?.data?.count || []
  );

  useEffect(() => {
    dispatch(fetchMeterListing(pageInfo, id, sortColumn, sortOrder));
  }, [dispatch, pageInfo.page, pageInfo.pageSize, id, sortColumn, sortOrder]);

  const handleAddButtonClick = () => {
    onAddButtonClick();
  };

  const handleEntriesListClick = (id, meter_id) => {
    onEntriesListClick(id, meter_id);
  };

  const handleEditButtonClick = (id) => {
    OnEditMeterButton(id);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <Grid container>
          <TableContainer
            component={Paper}
            sx={{
              bgcolor: "#2E813E20",
              boxShadow: "none",
              border: "1px solid #2E813E",
              maxWidth: isSmallScreen ? "100%" : "60%",
            }}
            size="small"
          >
            <MuiTable size="small">
              <TableHead sx={{ background: "#CBFFD5" }}>
                <TableRow sx={{ borderBottom: "none!important" }} size="small">
                  <TableCell
                    sx={{
                      "&:first-child": {
                        background: "#CBFFD5",
                      },
                      fontStyle: "italic",
                      borderBottom: "none!important",
                      padding: "4px 10px!important",
                      textWrap: "nowrap",
                      color: "#2E813E!important",
                      fontSize: "0.75rem!important",
                      fontWeight: 400,
                    }}
                  >
                    Meter type
                  </TableCell>
                  {Array.isArray(meterStatistics) &&
                    meterStatistics?.map((type, index) => (
                      <TableCell
                        key={type.meterType}
                        sx={{
                          color: "#111",
                          background: "#EBFFEF",
                          borderBottom: "none!important",
                          padding: "4px 4px!important",
                          textAlign: "center!important",
                        }}
                      >
                        {type?.["Meter type"]}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ background: "#CBFFD5" }}>
                <TableRow sx={{ borderBottom: "none!important" }} size="small">
                  <TableCell
                    sx={{
                      "&:first-child": {
                        background: "#CBFFD5",
                      },
                      fontStyle: "italic",
                      borderBottom: "none!important",
                      padding: "4px 10px!important",
                      textWrap: "nowrap",
                      color: "#2E813E!important",
                      fontSize: "0.75rem!important",
                      fontWeight: 400,
                    }}
                  >
                    Total meters
                  </TableCell>
                  {Array.isArray(meterStatistics) &&
                    meterStatistics?.map((count, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          color: "#111",
                          background: "#EBFFEF",
                          borderBottom: "none!important",
                          padding: "4px 4px!important",
                          textAlign: "center!important",
                        }}
                      >
                        {count?.["Total meters"]}
                      </TableCell>
                    ))}
                </TableRow>
                <TableRow sx={{ borderBottom: "none!important" }} size="small">
                  <TableCell
                    sx={{
                      "&:first-child": {
                        background: "#CBFFD5",
                      },
                      fontStyle: "italic",
                      borderBottom: "none!important",
                      padding: "4px 10px!important",
                      textWrap: "nowrap",
                      color: "#2E813E!important",
                      fontSize: "0.75rem!important",
                      fontWeight: 400,
                    }}
                  >
                    Current date
                  </TableCell>
                  {Array.isArray(meterStatistics) &&
                    meterStatistics?.map((date, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          color: "#111",
                          background: "#EBFFEF",
                          borderBottom: "none!important",
                          padding: "4px 4px!important",
                          textAlign: "center!important",
                        }}
                      >
                        {date?.["Current energy date"] &&
                          format(
                            new Date(date?.["Current energy date"]),
                            "yyyy-MM-dd"
                          )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Grid>
        <Button
          style={{
            backgroundColor: "transparent",
            padding: 0,
            minWidth: "unset",
            fontSize: "0.875rem",
            textWrap: "nowrap",
          }}
          disableRipple
          endIcon={
            <AddCircleIcon
              style={{
                color: "text.primary",
                fontSize: "2rem",
              }}
            />
          }
          onClick={handleAddButtonClick}
        >
          Add Meter
        </Button>
      </Box>
      <Box sx={{ marginTop: "2rem" }}>
        <Table
          columns={columns}
          data={meterListingData}
          count={meterCount}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          onClick={(id, res) => handleEntriesListClick(id, res?.meter_id)}
          cursorStyle="pointer"
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          tableClass="meter-listing-table"
          // isInaActiveRowClickDisabled = {true}
        />
      </Box>
      <EvModal
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
        actionButtonData={meterToDelete}
      />
      <Loader
        sectionLoader
        minHeight="100vh"
        loadingState={loadingState}
        loaderPosition="fixed"
      />
    </>
  );
};

export default MeterListing;
